import React from "react";
import SaleNotiPopup from "./SaleNotiPopup";
import SaleSettlementNotiPopup from "./SaleSettlementNotiPopup";
import UpdateNotiPopup from "./UpdateNotiPopup";

const Notification = ({ currentUser }) => {
  return (
    <>
      <UpdateNotiPopup currentUser={currentUser} />
      <SaleNotiPopup currentUser={currentUser} />
      <SaleSettlementNotiPopup currentUser={currentUser} />
    </>
  );
};

export default Notification;
