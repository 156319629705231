// @flow
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
} from "@material-ui/core";
import * as React from "react";
import { fetchHasSaleContract } from "../../utils/rest";
import NotiContents from "../../resources/images/noti";
function initStorageData() {
  let date = new Date();
  date.setFullYear(2024, 0, 10);

  return JSON.stringify({
    sd: new Date(),
    ed: date,
    hide: undefined,
  });
}

function setHideDate() {
  const storageData = JSON.parse(
    localStorage.getItem("sale_settlement_noti_popup")
  );
  const date = new Date();
  date.setDate(date.getDate() + 1);
  storageData.hide = date;
  localStorage.setItem(
    "sale_settlement_noti_popup",
    JSON.stringify(storageData)
  );
}

function isPopupRequired() {
  const storageData = JSON.parse(
    localStorage.getItem("sale_settlement_noti_popup")
  );
  const date = new Date();
  const endDate = new Date(storageData.ed);
  if (storageData?.hide) {
    const hide = new Date(storageData.hide);
    return (
      date.getTime() < endDate.getTime() && date.getTime() > hide.getTime()
    );
  }

  return date.getTime() < endDate.getTime();
}

const SaleSettlementNotiPopup = ({ currentUser }) => {
  const [check, setCheck] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const getData = async () => {
      const hasSaleContract = await fetchHasSaleContract(
        currentUser.tokens.access_token
      );

      const storageData = localStorage.getItem("sale_settlement_noti_popup");
      if (hasSaleContract && !storageData) {
        localStorage.setItem("sale_settlement_noti_popup", initStorageData());
        setOpen(true);
      } else if (hasSaleContract) {
        setOpen(isPopupRequired());
      }
    };

    getData();
  }, []);

  const handleClose = () => {
    if (check) {
      setHideDate();
    }
    setOpen(false);
  };

  return (
    <Dialog open={open} maxWidth={"md"}>
      <DialogContent>
        <img src={NotiContents.sale_settlement_noti} />
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "space-between", paddingLeft: "16px" }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={check}
              onChange={(_, checked) => setCheck(checked)}
              color="primary"
              size="small"
            />
          }
          label="오늘 하루 보지 않기"
        />
        <Button onClick={handleClose} color="primary">
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaleSettlementNotiPopup;
