// @flow
// type imports
import type { AppState } from '../../../types';
import type { AnlzSource, AnlzSourceListState, Patient } from '../../../types/analysis';
// module imports
import _ from 'lodash';
import * as React from 'react';
import moment from 'moment';
import Popup from 'reactjs-popup';
import { Query } from '@apollo/client/react/components';
import Drawer from '@material-ui/core/Drawer';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Tooltip from '@material-ui/core/Tooltip';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import styled from 'styled-components';
import { neuroInContractTypes} from "../../../utils/rest";
import {
  PanelContentContainer,
  SessionListContainer,
  FilenameSpan,
  FileDownloadLinkButton,
  FileContainer,
  DefaultCellSpan,
  ErrorMsgTooltipSpan,
  EdfConversionPrefContainer,
  ColoredCell,
} from './styled';
import { anlzSourceListActions } from '../../../ducks/analysis';
import { ANLZ_SRCS_BY_SBJTID_QUERY } from '../../../utils/graphql/queries';
import { SURVEY_SESSIONS_QUERY } from '../../../utils/graphql/queries';
import commonImages from '../../../resources/images/common';
import analysisImages from '../../../resources/images/analysis';
import {
  selectableTable,
  checkableTable,
  styledTable,
  Spinner,
  Checkbox,
  PanelGrid,
  AnchorButton,
  Button,
} from '../../common';
import { loadUserPreferences, setUserPreferences } from '../../../utils/localStorage';
import { useQuery } from '@apollo/client';
import { AnlzSourceDetailPanel } from '../AnlzSourceDetailPanel';
import { SurveySessionDetailPanel } from '../SurveySessionDetailPanel';
import type {CurrentUserState} from "../../../types/auth";
import { CreateButton, ReTempAnalzButton, RefreshButton, TempAnalzButton } from '../PatientDetail/styled';
import { ConfirmMessage } from '../AnlzRequestForm/styled';
import { FinePrintMessage } from '../AnlzRequestForm/styled';
import { ConfirmButton } from '../AnlzRequestForm/styled';
import { PDFDownloadButton } from './styled';
import { flushSync } from 'react-dom';
import { Backdrop, Box, Dialog, Portal } from '@material-ui/core';
import { MinddSocket } from '../../../utils/socket';

const SelectableTable = compose(styledTable, selectableTable, checkableTable)(ReactTable);

type OwnProps = {
  selectedPatient: ?Patient
};

type StateProps = AnlzSourceListState & {
  userEmail: string,
  currentUser: CurrentUserState
};

type ActionProps = typeof anlzSourceListActions;

type Props = StateProps & ActionProps & OwnProps;

type State = {
  convertToEDF: boolean
};

const ALL_CONTENT_SIZE = 2147483647;

// const { data: anlzSrcData, loading: anlzSrcLoading, error: anlzSrcError } = useQuery(ANLZ_SRCS_BY_SBJTID_QUERY, {
//   variables: {
//     subjectId: subjectId,
//     page: 0,
//     size: ALL_CONTENT_SIZE
//   },
//   fetchPolicy: 'network-only'
// });

const DetailContainer = styled(Drawer)`
  &.MuiDrawer-root {
    z-index: 10000;
  }
  .MuiDrawer-paper {
    background-color: #e7ecf1;
    width: ${props => (props.anchor === 'right' || props.anchor === 'left') ? `${props.width}px` : '100%'};
    height: ${props => (props.anchor === 'top' || props.anchor === 'bottom') ? `${props.height}px` : '100%'};
    .MuiToolbar-root {
      background-color: #e7ecf1;
      color: ${props => props.theme.black};
      background-color: ${props => props.theme.sandyGreen};
    }
    > div:last-child {
      margin: auto;
      margin-top: 0;
    }
  }
`;

const ToolbarPaper = styled(Paper)`
  && {
    position: sticky;
    top: 0;
    z-index: 1;
  }
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

const ToolbarButton = styled(IconButton)`
  &.MuiIconButton-root {
    color: ${props => props.theme.deepBlue};
  }
  &:first-child {
    margin-right: 8px;
  }
  &:not(:first-child) {
    margin-left: 8px
  }
`;

const CombinedAnlzSourceListComponent = (props: Props) => {

  const {
    enabledTmpAnlz,
    selectedPatient,
    selectedAnlzSource,
    checkedAnlzSources,
    userEmail,
    refetch,
    refetchAnlzSourcesDone,
    currentUser
  } = props;

  const [contractForNeuro, setContractForNeuro] = React.useState(false);

  React.useEffect(() => {
    const getData = async () => {
      const res = await neuroInContractTypes(currentUser.tokens.access_token)
      setContractForNeuro(res);
    };
    getData();
  }, []);

  const formatFileSize = (size: number) => {
    let dispSize = size;
    let unitIdx = 0;
    const sizeUnit = ['B', 'KB', 'MB', 'GB']
    while (dispSize >= 1000) {
      dispSize = dispSize / 1000;
      unitIdx += 1;
    }
    return `${dispSize.toFixed(3)} ${sizeUnit[unitIdx]}`
  }

  const formatPreProcStatus = (preProcStatus: string) => {
    switch(preProcStatus) {
      case 'PROCEEDING': return '변환 진행중';
      case 'COMPLETE': return '변환 완료';
      case 'REQUEST': return '변환 요청중';
      case 'FAIL': return '변환 실패';
      case 'CANCEL': return '변환 취소';
      case 'NONE': return '변환 안함';
      default: return '-';
    }
  }

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [edfOpen, setEdfOpen] = React.useState(false);

  const [convertToEDF, setConvertToEDF] = React.useState(loadUserPreferences(props.userEmail)['convertToEDF'] || false)

  const { data: anlzSrcData, loading: anlzSrcLoading, error: anlzSrcError, refetch: refetchAnlzSrc } = useQuery(ANLZ_SRCS_BY_SBJTID_QUERY, {
    context: {
      dataSourceType: 'api'
    },
    skip: !selectedPatient,
    fetchPolicy: 'network-only',
    variables: {
      subjectId: selectedPatient && selectedPatient.id,
      page: 0,
      size: ALL_CONTENT_SIZE,
    }
  });

  const { data: surveySessionData, loading: surveySessionLoading, error: surveySessionError, refetch: refetchSurveySession } = useQuery(SURVEY_SESSIONS_QUERY, {
    context: {
      dataSourceType: 'api'
    },
    skip: !selectedPatient,
    fetchPolicy: 'network-only',
    variables: {
      subjectId: selectedPatient && selectedPatient.id,
      page: 0,
      size: ALL_CONTENT_SIZE,
    },
  });

  let combinedAnlzSrc = [];
  if (anlzSrcData && anlzSrcData.anlzSrcPgBySbjtId && surveySessionData && surveySessionData.surveySessionSummaries) {
    combinedAnlzSrc = _.concat(_.map(anlzSrcData.anlzSrcPgBySbjtId.sources, (source) => ({
      source,
      id: `FILE_${source.id}`
    })), _.map(surveySessionData.surveySessionSummaries.surveySessions, (session) => ({
      source: session,
      id: `SURVEY_${session.sessionId}`
    })));
    combinedAnlzSrc = _.orderBy(combinedAnlzSrc, rowData => (rowData.source && (rowData.source.created || rowData.source.createdTime)), 'desc');
  }

  React.useEffect(() => {
    async function refetchData() {
      console.log('refetch');
      await refetchAnlzSrc();
      await refetchSurveySession();
      refetchAnlzSourcesDone();
    }
    if (refetch) {
      refetchData();
    }
  }, [refetch]);

  React.useEffect(() => {
    props.uncheckAnlzSources(props.checkedAnlzSources);
  }, [selectedPatient]);

  console.log(anlzSrcError);

  const renderDetailPanel = () => {
    if (!selectedAnlzSource) {
      return null;
    }
    if (_.startsWith(selectedAnlzSource.id, 'SURVEY_')) {
      return (
        <PanelGrid.Row style={{ height: selectedAnlzSource.source.submitted ? '673px' : '460px'}}>
          <PanelGrid.Col minWidth={1175}>
            <SurveySessionDetailPanel selectedPatient={selectedPatient} selectedSurveySession={selectedAnlzSource.source} />
          </PanelGrid.Col>
        </PanelGrid.Row>
      );
    }
  }

  const selectedAnlzSourceTitle = () => {
    if (!selectedAnlzSource) {
      return null;
    }
    if (_.startsWith(selectedAnlzSource.id, 'FILE_')) {
      return `${selectedAnlzSource.source.type}: ${selectedAnlzSource.source.visualFile}`;
    } else if (_.startsWith(selectedAnlzSource.id, 'SURVEY_')) {
      return `설문: ${selectedAnlzSource.source.pubSurveyName}${(selectedAnlzSource.source.pubSurveyDesc) ? `(${selectedAnlzSource.source.pubSurveyDesc.split('(')[0]})` : ''}`;
    }
  }
  
  return (
    <PanelContentContainer padding='13px 0px 45px 0px'>
      <SessionListContainer>
        <SelectableTable
          data={(!selectedPatient) ? [] : combinedAnlzSrc}
          isRowCheckable={(rowData) => !(rowData.source && rowData.source.lastAnlzReq && rowData.source.lastAnlzReq.anlzStatus === 'PROCEEDING')}
          loading={(!selectedPatient) ? false : anlzSrcLoading || surveySessionLoading}
          minRows={0}
          LoadingComponent={Spinner}
          columns={[{
              id: 'created',
              Header: '일시',
              minWidth: 150,
              accessor: rowData => {
                if (_.startsWith(rowData.id, 'FILE_')) {
                  return moment(rowData.source.created).format('YYYY-MM-DD HH:mm');
                } else if (_.startsWith(rowData.id, 'SURVEY_')) {
                  return moment(rowData.source.createdTime).format('YYYY-MM-DD HH:mm');
                }
              },
            }, {
              id: 'type',
              Header: '유형',
              minWidth: 100,
              accessor: rowData => {
                if (_.startsWith(rowData.id, 'FILE_')) {
                  // TODO: check file's type to determine if EEG or HRV
                  return rowData.source.type == 'ECG' ? 'HRV' : rowData.source.type;
                } else if (_.startsWith(rowData.id, 'SURVEY_')) {
                  return '설문';
                }
              }
            }, {
              id: 'summary',
              minWidth: 350,
              Header: '요약',
              Cell: (rowInfo) => {
                if (rowInfo.value.type === 'survey') {
                  return <FileContainer><FilenameSpan>{rowInfo.value.info}</FilenameSpan></FileContainer>;
                } else if (rowInfo.value.type === 'file') {
                  return (
                    <FileContainer>
                      <FilenameSpan customTitle={rowInfo.value.info.visualName}>
                        <FileDownloadLinkButton
                          onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            if (selectedPatient) {
                              props.downloadAnlzResource(selectedPatient.id, encodeURIComponent(rowInfo.value.info.actualName), rowInfo.value.info.visualName);
                            }
                          }}
                          href='#'
                          download
                        >
                          <img src={commonImages.download} alt='Download' />
                          <img src={commonImages.downloadHover} alt='Download' className="hover-img"/>
                        </FileDownloadLinkButton>
                        {rowInfo.value.info.visualName}
                      </FilenameSpan>
                      <span>({formatFileSize(rowInfo.value.info.fileSize)})</span>
                    </FileContainer>
                  );
                }
              },
              accessor: rowData => {
                if (_.startsWith(rowData.id, 'FILE_')) {
                  return { type: 'file', info: { visualName: rowData.source.visualFile, actualName: rowData.source.actualFile, fileSize: rowData.source.fileSize } };
                } else if (_.startsWith(rowData.id, 'SURVEY_')) {
                  return { type: 'survey', info: `${rowData.source.pubSurveyName}${(rowData.source.pubSurveyDesc) ? `(${rowData.source.pubSurveyDesc.split('(')[0]})` : ''}` };
                }
              },
            }, {
              id: 'remarks',  // pre-processed file or survey session status
              minWidth: 300,
              Header: '비고',
              Cell: (rowInfo) => {
                if (rowInfo.value.type === 'survey') {
                  if (rowInfo.value.info === 'SUBMITTED') {
                    return <ColoredCell color='#576881'>완료</ColoredCell>;
                  } else if (rowInfo.value.info === 'INCOMPLETE') {
                    return <ColoredCell color='#fc5882'>미완료</ColoredCell>
                  }
                  return <ColoredCell color='#9faabf'>만료</ColoredCell>;
                } else if (rowInfo.value.type === 'file') {
                  const {
                    visualName,
                    actualName,
                    fileSize,
                    preProcStatus,
                    remarks
                  } = rowInfo.value.info;
                  if (visualName && actualName) {
                    return (
                      <FileContainer>
                        <FilenameSpan customTitle={visualName}>
                          <FileDownloadLinkButton
                            onClick={(event) => {
                              event.stopPropagation();
                              event.preventDefault();
                              if (selectedPatient) {
                                props.downloadAnlzResource(selectedPatient.id, encodeURIComponent(actualName), visualName);
                              }
                            }}
                            href='#'
                            download
                          >
                            <img src={commonImages.download} alt='Download' />
                            <img src={commonImages.downloadHover} alt='Download' className="hover-img"/>
                          </FileDownloadLinkButton>
                          {visualName}
                        </FilenameSpan>
                        <span>({formatFileSize(fileSize)})</span>
                      </FileContainer>
                    );
                  } else if (preProcStatus && preProcStatus !== 'FAIL') {
                    return <span>{formatPreProcStatus(preProcStatus)}</span>;
                  } else if (preProcStatus === 'FAIL') {
                    return (
                      <FilenameSpan
                        customTitle={<ErrorMsgTooltipSpan>{JSON.parse(remarks)['msg']}</ErrorMsgTooltipSpan>}
                        alwaysShowTooltip
                      >
                        {formatPreProcStatus(preProcStatus)}
                      </FilenameSpan>
                    );
                  }
                  return '-';
                }
              },
              accessor: rowData => {
                if (_.startsWith(rowData.id, 'FILE_')) {
                  return { type: 'file', info: {
                    visualName: rowData.source.preProcVisualFile,
                    actualName: rowData.source.preProcActualFile,
                    fileSize: rowData.source.preProcFileSize,
                    preProcStatus: rowData.source.preProcStatus,
                    remarks: rowData.source.remarks
                  } };
                } else if (_.startsWith(rowData.id, 'SURVEY_')) {
                  return { type: 'survey', info: (rowData.source.submitted) ? 'SUBMITTED' : 'INCOMPLETE' };
                }
              },
            },
            // , {
            //   id: 'preProcFileName',
            //   minWidth: 400,
            //   Header: '(외부)분석용 파일명',
            //   Cell: (rowInfo) => {
                
            //   },
              // accessor: rowData => (),
            // }
          ]}
          showPagination={false}
          resizable={false}
          multiSort={false}
          sortable={false}
          selectedRow={selectedAnlzSource}
          checkedRows={checkedAnlzSources}
          onRowsCheck={(rowIds: string[]) => {
            props.checkAnlzSources(rowIds);
          }}
          onRowsUncheck={(rowIds: string[]) => {
            props.uncheckAnlzSources(rowIds);
          }}
          onRowSelect={(rowInfo: any) => {
            const anlzSource: AnlzSource = rowInfo.original;
            props.selectAnlzSource(anlzSource);
            if(_.startsWith(anlzSource.id, 'FILE_')) {
              setEdfOpen(true);
            } else {
              setDrawerOpen(true);
            }
            
            
          }}
          noDataText=''
        />
        {
          contractForNeuro?
            <EdfConversionPrefContainer>
              <Checkbox
                checked={convertToEDF}
                onChange={(event) => {
                  setUserPreferences(userEmail, 'convertToEDF', !convertToEDF);
                  setConvertToEDF(!convertToEDF);
                }}
                stopClickPropagation
                checkboxAlign='right'
                checkedImgSrc={commonImages.checkedPrimary}
                uncheckedImgSrc={commonImages.check}
              >
                Filtered EDF 생성 사용
              </Checkbox>
            </EdfConversionPrefContainer>:''
        }

      </SessionListContainer>
      <Dialog open={edfOpen} fullScreen style={{padding: '16px'}}>
        <Box style={{height: '100%', position: 'relative'}}>
          <IconButton  style={{position: 'fixed', right: '36px', top: '24px', background: '#d9d0d0a6', borderRadius: '8px'}}  onClick={() => {
             setEdfOpen(false);
             props.deselectAnlzSource();
          }}>
          <CloseSharpIcon/>
          </IconButton>
        {selectedAnlzSource &&  <AnlzSourceDetailPanel selectedPatient={selectedPatient} selectedAnlzSource={selectedAnlzSource.source} />}
         </Box>
      </Dialog>
      <DetailContainer
        width={1295}
        variant='temporary'
        anchor='right'
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
          props.deselectAnlzSource();
        }}
        // PaperProps={{
        //   ref: containerRef
        // }}
      >
        <ToolbarPaper>
          <Toolbar>
            <Tooltip title='닫기'>
              <ToolbarButton
                onClick={() => {
                  props.deselectAnlzSource();
                  setDrawerOpen(false);
                }}
                size='small'
              >
                <CloseSharpIcon/>
              </ToolbarButton>
            </Tooltip>
            <Typography
              variant='subtitle1'
              noWrap
            >
              {selectedAnlzSourceTitle()}
            </Typography>
            <Spacer/>
          </Toolbar>
        </ToolbarPaper>
        <Divider/>
        <PanelGrid.Grid>
          {renderDetailPanel()}
        </PanelGrid.Grid>
      </DetailContainer>
    </PanelContentContainer>
  );
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    ...state.analysis.anlzSourceList,
    userEmail: state.auth.currentUser.user_name,
    currentUser: state.auth.currentUser
  };
}

export const CombinedAnlzSourceList = connect(mapStateToProps, anlzSourceListActions)(CombinedAnlzSourceListComponent);
